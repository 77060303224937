import { effect, inject, Injectable, signal, WritableSignal } from "@angular/core";
import { IPropertyEventDetails } from "@root/data/market/properties/models/property-event-details.model";
import { IPropertyEventInstance } from "@root/data/market/properties/models/property-event-instance.model";
import { PropertiesEventsService } from "@root/data/market/properties/services/properties-events.service";
import { EventInstanceFilter } from "@root/shared/enums/property-event-instances-filtering.enum";
import { EventInstanceSortBy } from "@root/shared/enums/property-event-instances-sorting.enum";
import { IPropertyEventInstancesPayload } from "@root/shared/interfaces/property-event-instances-payload.interface";
import {
  constructInitialSignalPaginatedResponse,
  copyPaginatedSignalResponse,
} from "@root/shared/utilities/signals.utilities";
import { finalize } from "rxjs/internal/operators/finalize";

@Injectable({ providedIn: "root" })
export class EventDetailsSignalStateService {
  readonly #propertyEventsService = inject(PropertiesEventsService);

  isEventDetailsLoading = signal(false);
  isInstanceLoading = signal(false);
  updateEventInstances = signal(false);

  eventId: WritableSignal<number | undefined> = signal<number | undefined>(undefined);
  propertyId: WritableSignal<number | undefined> = signal<number | undefined>(undefined);

  // instances payload
  filterBy: WritableSignal<EventInstanceFilter> = signal(EventInstanceFilter.All);
  sortBy: WritableSignal<EventInstanceSortBy> = signal(EventInstanceSortBy.Id);
  sortDescending: WritableSignal<boolean> = signal(false);

  eventDetails = signal<IPropertyEventDetails | undefined>(undefined);
  paginatedEventInstances = constructInitialSignalPaginatedResponse<IPropertyEventInstance>();
  isSeriesEvent = signal(false);

  readonly #loadDetailsEffect$ = effect(
    () => {
      if (this.propertyId() && this.eventId()) this.#getEventDetails();
    },
    { allowSignalWrites: true },
  );

  readonly #loadEventInstancesEffect$ = effect(
    () => {
      if (this.propertyId() && this.eventId()) {
        this.#getEventInstances({
          filterBy: this.filterBy(),
          sortBy: this.sortBy(),
          sortDescending: this.sortDescending(),
          pageNumber: this.paginatedEventInstances.currentPage(),
          pageSize: this.paginatedEventInstances.pageSize(),
        });
        this.updateEventInstances();
      }
    },
    { allowSignalWrites: true },
  );

  #getEventDetails() {
    this.isEventDetailsLoading.set(true);
    this.#propertyEventsService
      .getEventDetails(this.propertyId()!, this.eventId()!)
      .pipe(finalize(() => this.isEventDetailsLoading.set(false)))
      .subscribe((response) => {
        this.eventDetails.set(response);
        this.isSeriesEvent.set(response.repetitionCycle !== null);
      });
  }

  #getEventInstances(queryParams: IPropertyEventInstancesPayload) {
    this.isInstanceLoading.set(true);
    this.#propertyEventsService
      .getEventInstances(this.propertyId()!, this.eventId()!, queryParams)
      .pipe(finalize(() => this.isInstanceLoading.set(false)))
      .subscribe((response) => {
        copyPaginatedSignalResponse(this.paginatedEventInstances, response);
      });
  }
}
