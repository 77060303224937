import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltip } from "@angular/material/tooltip";

@Component({
  selector: "est-tooltip",
  standalone: true,
  imports: [TranslateModule, MatTooltip],
  templateUrl: "./tooltip.component.html",
  styleUrl: "./tooltip.component.scss",
})
export class TooltipComponent {
  tooltip = input.required<string>();
}
