import { Component, inject, input } from "@angular/core";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltip } from "@angular/material/tooltip";
import { MatDialog } from "@angular/material/dialog";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";

@Component({
  selector: "est-dialog-header",
  standalone: true,
  imports: [NgOptimizedImage, TranslateModule, MatTooltip, NgClass, TooltipComponent],
  templateUrl: "./dialog-header.component.html",
  styleUrl: "./dialog-header.component.scss",
})
export class DialogHeaderComponent {
  label = input.required<string>();
  tooltipLabel = input.required<string>();
  includeExitButton = input<boolean>(false);
  appliedClasses = input<string>("");
  dialog = inject(MatDialog);
  closeDialog(): void {
    this.dialog.closeAll();
  }
}
